<template>
    <div class="container_site">
        <Dimensions color_title="#FFFFFF" />
    </div>        
</template>
<script>
import viewDimensions from '@/components/views/Dimensions'
export default {

    name: 'Indicators',

    data(){
        return {
            
        }
    },
    components:{
        Dimensions: viewDimensions,
    },
    methods:{
      
    }

};
</script>

<style lang="scss" scoped>
</style>